<template>
    <div class="podcasts-landing">
        <div class="hero">
            <HeroBasic
                :heading="title"
                accentColor="grey"
                class="podcasts-heading"
                :showTopographic="true"
                :hasHeaderOffset="true"
            />
        </div>

        <div class="podcasts-body">
            <div class="podcast-summaries">
                <div v-for="(podcast, index) in podcasts" :key="index" class="podcast-summary">
                    <div class="podcast-detail">
                        <Typography as="h3" variant="h3-display" transform="capitalize" class="podcast-title">
                            <Anchor :url="podcast.path">
                                {{ podcast.title }}
                            </Anchor>
                        </Typography>

                        <div v-if="podcast.contributors?.length" class="contributors">
                            <Typography variant="body-display-small">
                                <Typography variant="body-display-micro-italic" transform="capitalize" class="by-line">
                                    {{ $t('hosted by') }}&nbsp;
                                </Typography>
                                <template v-for="(person, index) in podcast.contributors" :key="person.firstName">
                                    <Anchor :url="person.path" class="contributor-profile">{{
                                        getPersonFullName(person)
                                    }}</Anchor>
                                    <template v-if="index === podcast.contributors?.length - 2">, and </template>
                                    <template v-else-if="index !== podcast.contributors.length - 1">, </template>
                                </template>
                            </Typography>
                        </div>
                        <RichTextElements
                            v-if="podcast.dek"
                            :elements="podcast.dek"
                            body-style="serif-large"
                            class="podcast-dek"
                        />
                        <AttributionLabel
                            v-if="podcast.parent.value"
                            :title="podcast.parent.value.title"
                            :logo="podcast.parent.value.attributionLogo"
                            :url="podcast.parent.value.path"
                        />
                    </div>

                    <Image v-if="podcast.hero" v-bind="podcast.hero.image" class="podcast-hero" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
    },
    podcasts: {
        type: Array,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const headerAttributesStore = useHeaderAttributesStore();
const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Podcast Landing',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.podcasts-body {
    @include content-section;
}

.podcast-summary {
    @include grid;
    @include padding-tb(vertical-space(4));

    &:not(last-child) {
        border-bottom: $border;
    }

    @include media-query(phone) {
        display: block;
    }
}

.podcast-detail {
    grid-column: 1 / span 6;
    @include padding-lr(var(--col-gutter));

    @include media-query(phone) {
        margin-bottom: vertical-space(1);
    }

    @include media-query(tablet) {
        grid-column: 1 / span 8;
    }
}

.podcast-hero {
    grid-column: 11 / span 2;
    @include padding-lr(var(--col-gutter));

    @include media-query(tablet) {
        grid-column: 9 / span 4;
    }
}

.podcast-title {
    color: color(blue, default, 0.8);
    margin-bottom: 0.8rem;
}

.contributors {
    .by-line {
        color: color(noir, default, 0.6);
    }

    .contributor-profile {
        color: color(noir, default, 0.9);
        text-decoration: underline;
        text-decoration-color: color(teal);
    }
}

.podcast-dek {
    color: color(noir, default, 0.8);
    margin-bottom: 0.8rem;
}
</style>
